<template>
    <div class="container" id="container">
      <div class="col-lg-12 mb-12">
        <p class="text-left mx-auto" style="max-width: 600px">アンケートは回答済みです。<br>研究同意の撤回は随時受け付けます。<br>以下に連絡お願いいたします。<br><br><br>研究者連絡先：<br>東京医科歯科大学&nbsp;&nbsp;大学院医歯学総合研究科&nbsp;&nbsp;口腔基礎工学分野&nbsp;&nbsp;教授&nbsp;&nbsp;青木和広<br>所在地：〒113-8549&nbsp;&nbsp;東京都文京区湯島1-5-45<br>電話番号（直通）：03-5803-4641&nbsp;&nbsp;FAX：同上<br>e-mail:&nbsp;&nbsp;kazu.hpha@tmd.ac.jp<br><br>応募及び専用サイトについてのお問い合わせ：<br>株式会社ナンバーワンソリューションズ&nbsp;&nbsp;担当田中<br>電話番号：03-6412-8470<br>e-mail：info@no1s.biz</p>
        <p class="text-left mt-5">試料採取方法の詳細は以下から確認できます</p>
        <YoutubeComponent @ready="onReady" :id="video2" @watched="onWatched"></YoutubeComponent>
      </div>
    </div>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { getMemberCode } from '@/utils/common.js';
import { getActivateFlg } from '@/api/member.js';
import { CONST, config } from '@/constants.js';
import YoutubeComponent from '@/components/Youtube'

export default {
  mixins: [Mixin],
  components: { YoutubeComponent },
  data() {
    return {
      video2: config.VIDEO_ID2,
    }
  },
  async created() {
    const memberCode = getMemberCode()
    const activate_flg = await getActivateFlg(memberCode)

    switch(activate_flg.value) {
      case CONST.MEMBER.ACTIVATE_FLG.RESEARCH_SUBJECT:
        this.$router.push({name: 'RegisterResearch'})
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
        this.$router.push({name: 'Explain'})
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
        break
      default:
        this.$router.push({name: 'LoginPage'})
        break
    }
  },
}
</script>
